.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.ag-theme-alpine {
  font-size: 9pt;
  --ag-row-height: 20px;

  .ag-header-cell {
    font-size: 9pt;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .ag-cell-label-container {
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    margin: 0 auto;
  }

  .ag-cell {
    font-size: 9pt;
    padding-left: 5px;
    padding-right: 5px;
  }
}