@font-face {
    font-family: 'NotoSansKR';
    src: url('./NotoSansKR-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BinggraeBold';
    src: url('./Binggrae-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}