body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ag-theme-alpine {
  font-size: 9pt;
  --ag-row-height: 20px;
}

.static-cell {
  font-size: 10pt;
  text-align: center;
}

.number-cell {
  font-size: 10pt;
  text-align: right;
  padding-left: 2px;
  padding-right: 5px;
}